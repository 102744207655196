<template>
  <PageLayout>
    <template #header>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h1>{{ $t('payment.reconciliationStatementReport.title') }}</h1>
          <p v-if="currentSupplier">{{ currentSupplier.name }}</p>
        </div>
        <el-select v-if="currentSupplier" v-model="supplierId" size="mini" placement="end" filterable>
          <el-option v-for="supplier in suppliers" :key="supplier.id" :label="supplier.name" :value="supplier.id" />
        </el-select>
      </div>
    </template>
    <template v-if="currentSupplier" #tabs>
      <Tabs :tabs="tabs" :active-tab.sync="activeTab" />
    </template>
    <template v-if="currentSupplier">
      <RestaurantPerspective
        v-if="activeTab === 0 && currentSupplier"
        :supplier="currentSupplier"
        :restaurant="currentTenant"
      />
      <SupplierPerspective
        v-if="activeTab === 1"
        :supplier="currentSupplier"
        :document-type="DOCUMENT_TYPES.RECONCILIATION_STATEMENT"
        :documents="documents"
        :documents-loading="documentsLoading"
      />
    </template>
    <div v-else v-loading style="height: 50vh" />
  </PageLayout>
</template>

<script type="text/javascript">
import { ref, computed, getCurrentInstance } from 'vue';

import { PageLayout, Tabs } from '@/modules/core';
import { useTenancy } from '@/modules/auth';
import { useSuppliersNew } from '@/modules/suppliers';
import { DOCUMENT_TYPES } from '@/modules/document/types';
import { useLast12Documents } from '@/modules/document/compositions';

import { RestaurantPerspective, SupplierPerspective } from './components';

export default {
  components: { PageLayout, Tabs, RestaurantPerspective, SupplierPerspective },
  setup() {
    const { $route } = getCurrentInstance().proxy;
    const supplierId = ref($route.params.supplierId);
    const { currentTenant } = useTenancy();
    const { suppliers, loading: suppliersLoading } = useSuppliersNew();
    const { documents, loading: documentsLoading } = useLast12Documents(
      computed(() => ({
        businessId: currentTenant.value.id,
        supplierId: supplierId.value,
        types: [DOCUMENT_TYPES.RECONCILIATION_STATEMENT],
      }))
    );

    return {
      documents,
      documentsLoading,
      activeTab: ref(0),
      currentTenant,
      supplierId,
      suppliers,
      suppliersLoading,
      DOCUMENT_TYPES,
    };
  },
  computed: {
    tabs() {
      return [
        {
          text: this.$t('payment.reconciliationStatementReport.tabs.restaurantPerspective', {
            restaurant: this.currentTenant.name,
          }),
        },
        {
          text: this.$t('payment.reconciliationStatementReport.tabs.supplierPerspective', {
            supplier: this.currentSupplier?.name,
          }),
        },
      ];
    },
    currentSupplier() {
      return this.suppliers.find((supplier) => supplier.id === this.supplierId);
    },
  },
  watch: {
    supplierId(supplierId) {
      this.$router.replace({ params: { supplierId } });
    },
  },
};
</script>
